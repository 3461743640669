<template>
  <div class="relatedContentayout">
    <div v-if="dataFetched">
        <div v-if="relatedContentDetails && relatedContentDetails.length == 0" class="error-message">
          <div class="EmptyContainer">
            <EptyBin></EptyBin>
            <p> {{$t("No contents found")}} </p>
          </div>
        </div>
        <Grid :contents="relatedContentDetails" :isScrollable="isScrollable" @loadMore="loadMoreContents()" v-else></Grid>
    </div>
    <div v-if="!dataFetched">
      <GridLayoutPlaceholder></GridLayoutPlaceholder>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { store } from "@/store/store";

export default {
  props: {
    contentDetails: {
      type: Object,
    },
  },
  data() {
    return {
      dataFetched: false,
      seasonNum: 1,
      seasonCount: null,
      localDisplayLang: null,
      seasonCountArray: [],
      isMpegRequired: false,
      relatedContentDetails: null,
      containerHover: "container",
      noContainerHover: "container-no-hover",
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
      pageNum: 0,
      isScrollable: false,
      pageChangeKey: 1,
      totalRelatedCount: 0
    };
  },

  computed: {
    checkSeries: function() {
      if (this.contentDetails.category == "MOVIE") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;
    this.pageNum = this.pageNum + 1;
    this.fetchRelatedContents(this.$route.params.contentId);
  },
  mounted() {
    this.totalRelatedCount = 0;
    eventBus.$on("relatedcontents-response", (response) => {     
      // alert("related") 
      if(!this.relatedContentDetails) {
        this.relatedContentDetails = [];
      }
      this.dataFetched = true;
      if(!response.errorcode) {
        this.relatedContentDetails = [...this.relatedContentDetails, ...response.data];
      } else {
        this.relatedContentDetails = [];
      }        
      this.totalRelatedCount = response.totalcount;
      this.pageChangeKey = this.pageChangeKey + 1;

    });
  },
  methods: {
    getDescription(related) {
      if(related.shortdescription && related.shortdescription.length > 250) {
        return related.shortdescription.slice(0, 250);
      } else if (related.longdescription && related.longdescription.length > 250) {
        return related.longdescription.slice(0, 250);
      } else if(related.shortdescription) {
        return related.shortdescription;
      } else if(related.longdescription) {
        return related.longdescription;
      }
    },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    fetchRelatedContents(contentId) {
      let pageSize = 15;
      let payload = {
        contentid: contentId,
        params: {
          displaylanguage: this.localDisplayLang == "ml" ? "mal" : this.localDisplayLang,
          pagesize: pageSize,
          page: this.pageNum,
        },
      };

      if (this.isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }
      // eventBus.$emit("getRelatedcontents", payload);
      if(!this.relatedContentDetails) {
        this.relatedContentDetails = [];
      }
      store
      .dispatch("relatedContents", payload)
      .then((response) => {
        if (response.data) {
          // eventBus.$emit("relatedcontents-response", response.data);
          this.dataFetched = true;
          if(!response.data.errorcode) {
            this.relatedContentDetails = [...this.relatedContentDetails, ...response.data.data];
          } else {
            this.relatedContentDetails = [];
          } 
          this.totalRelatedCount = response.data.totalcount;    
          console.log('totlacount', response.data.totalcount)   
          this.isScrollable = response.data.totalcount == this.relatedContentDetails.length ? false : true;
          this.pageChangeKey = this.pageChangeKey + 1;
          this.pageNum = this.pageNum + 1
          // alert(this.pageChangeKey);

        }
      }).catch((error)=>{console.log("error==>", error)});
    },

    getPoster(content) {  
      if (content && content.poster && content.poster.length && !content.inwatchlist) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          // return content.poster[index].filelist[1].filename;
          if(content.poster[index] && content.poster[index].filelist) {
            if(content.poster[index].filelist[1]){
              return content.poster[index].filelist[1].filename;
            }else if(content.poster[index].filelist[0]){
              return content.poster[index].filelist[0].filename;
            }else {
              return this.placeholder_lands;
            }
          }
        } else {
          return this.placeholder_lands;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      } else if  (content.thumbnail ) {
          return content.thumbnail;
        } else {
          return this.placeholder_lands;
        }
    },
    loadMoreContents() {
      if(this.totalRelatedCount > this.relatedContentDetails.length) {
        // this.pageNum = this.pageNum + 1
        this.fetchRelatedContents(this.$route.params.contentId);
      } else {
        this.isScrollable = false;
      }
    },
  },
  components: {
    Grid: () => import(/* webpackChunkName: "Grid" */ "@/components/Templates/GridLayout.vue"),
    EptyBin: () => import(/* webpackChunkName: "eptyBin" */ "@/components/SvgImages/Empty.vue"),
    GridLayoutPlaceholder: () => import(/* webpackChunkName: "gridplaceholder" */ "@/components/placeholders/GridLayoutPlaceholder.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./relatedCard.scss";

.relatedContentayout {
  margin-top: 0.7rem;
}
</style>
